<template>
    <div style="background: #f6f7fb">
        
        <div class="card">
            <el-page-header @back="goBack"  :content="selectType==6?'编辑定金单':'新增定金单'"></el-page-header>
        </div>

        <div class="form">
            <el-form v-model="form" :inline="true" label-width="80px" :model="form" :rules="rules" ref="form">

                 <el-form-item label="单据日期" prop="create_time">
                    <el-date-picker
                        :picker-options="pickerBeginDateBefore"
                        v-model="form.create_time"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="定金类型" prop="type_seq" style="margin-left:20px;">
                    <el-select @change="selectChange3" v-model="form.type_seq" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="客户" prop="customer_id">
                    <el-select @change="selectChange2" v-model="form.customer_id" clearable="" filterable="" placeholder="请输入姓名或电话" remote :remote-method="remoteMethod" :loading="selectLoading">
                    <template v-for="item in userList">
                        <el-option :label="item.realname + '-' + item.username" :value="item.client_user_id" :key="item.client_user_id"></el-option>
                    </template>
                    </el-select>
                </el-form-item>

                <el-form-item label="业务员" prop="salesman_store_id">
                    <el-select @change="selectChange" v-model="form.salesman_store_id" clearable="" filterable="">
                    <template v-for="item in employee">
                        <el-option :label="item.realname" :value="item.store_user_id" :key="item.store_user_id"></el-option>
                    </template>
                    </el-select>
                </el-form-item>

                <!-- 退定金 &&selectType!=6-->
                <template v-if="form.type_seq==20">
                    <el-form-item label="退定金单号"  label-width="100px">
                        <el-select @change="selectChange4" v-model="form.back_id">
                            <template v-for="item in returnList">
                                <el-option :label="item.sn" :value="item.deposit_single_id" :key="item.deposit_single_id"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>

                    <el-form-item style="margin-left:20px;" label="定金金额" >
                        <span>{{totalSum}}</span>
                    </el-form-item>

                </template>

                <!-- 编辑 -->
                <template v-if="selectType==6">

                    <el-descriptions column=4 style="width:66%;margin:20px 12px 0;">
                        <el-descriptions-item label="定金单号">{{form.sn}}</el-descriptions-item>
                        <el-descriptions-item label="门店">{{form.hierarchy_name}}</el-descriptions-item>
                        <el-descriptions-item label="创建人">{{form.store_user_name}}</el-descriptions-item>
                        <el-descriptions-item label="单据状态">
                            <el-tag size="small" v-if="form.status===10" type="info">待审核</el-tag>
                            <el-tag size="small" v-if="form.status===20">已通过</el-tag>
                            <el-tag size="small" v-if="form.status===30" type="warning">未通过</el-tag>
                        </el-descriptions-item>
                    </el-descriptions>

                </template>

            </el-form>

            <div style="margin:10px 0;">
                <el-button type="primary" @click="addRow">增加行</el-button>
                <!-- <el-button type="primary" @click="addVisible=true" icon="el-icon-plus">支付渠道</el-button> -->
            </div>

            <!-- 表格 -->
            <el-table :data="list" border  style="width: 100%" show-summary height="300px" ref="payData">
                <el-table-column
                    type="index"
                    width="150"
                    align="center"
                    label="序号">
                </el-table-column>

                <el-table-column
                    align="center"
                    label="支付方式"
                    width="300px">
                    <template slot-scope="scope">
                        <el-select
                            v-model="list[scope.$index].pay_channel_id"
                            clearable=""
                            filterable="">
                        <template v-for="item in pay_channel">
                        <el-option
                            :label="item.name"
                            :value="item.pay_type"
                            :key="item.pay_type"
                        ></el-option>
                        </template>
                    </el-select>
                   </template>
                </el-table-column>

                <el-table-column
                    align="center"
                    prop="amount"
                    label="金额"
                    width="300px">
                    <template slot-scope="scope">
                        <el-input v-model="list[scope.$index].amount"  placeholder="请输入金额"></el-input>
                    </template>
                </el-table-column>

                <el-table-column
                    align="center"
                    label="明细备注">
                        <template slot-scope="scope">
                            <el-input   placeholder="请输入明细备注" maxlength="100" resize="none"  v-model="list[scope.$index].detail_note"></el-input>
                        </template>
                </el-table-column>

                <el-table-column
                    align="center"
                    label="操作"
                    width="200px">
                    <template slot-scope="scope">
                        <el-link type="danger" @click='deleteRow(scope.$index)'>移除</el-link>
                    </template>
                </el-table-column>

            </el-table>

                <div class="submitForm" style="margin-top:10px;">
                    <el-form v-model="form" :inline="true" label-width="80px">
                            <el-form-item label="备注">
                                <el-input type="textarea" :rows="5" v-model="form.note" style="width: 535px;"></el-input>
                            </el-form-item>

                            <template v-if="form.type_seq==20">
                                <div style="margin-left:40px;">
                                    <span>实退金额:</span>
                                    <span style="color:#F56C6C;display:inline-block;margin-left:10px;">￥{{totalSum}}</span>
                                </div>
                            </template>
                          
                            
                    </el-form>
                </div>
                
                <template v-if="selectType==6">
                   <el-descriptions column=4 style="width:66%;margin:20px 40px 0;">
                        <el-descriptions-item label="审核人">{{form.audit_store_user_name}}</el-descriptions-item>
                        <el-descriptions-item label="审核时间">{{form.audit_time}}</el-descriptions-item>
                    </el-descriptions>
                </template>

                <div style="height:80px;"></div>
              
                <div class="btn2">
                    <el-button type="primary" @click="onSubmit('form')" :loading="saveLoading">提交审核</el-button>
                    <el-button @click="goBack">取消</el-button>
                </div>
        </div>

         <el-dialog
            title="新增支付渠道"
            :visible.sync="addVisible"
            width="30%"
            :before-close="handleClose">
            <el-form :model="addForm" label-width="80px">
                <el-form-item label="支付渠道">
                <el-input placeholder="请输入" v-model="addForm.name"></el-input>
                </el-form-item>
                <el-form-item label="收支方式">
                <el-radio-group v-model="addForm.type">
                    <el-radio :label="20">支出</el-radio>
                </el-radio-group>
                </el-form-item>
                <el-form-item label="状态">
                <el-switch
                    :active-value="10"
                    :inactive-value="20"
                    v-model="addForm.status">
                </el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleAddStreamType">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import LocalPrint from "@/components/localPrint/Index.vue";
import {getUserListReq,getEmployeeReq,addStreamTypeReq,getPayChannelCtlReq}  from '@/api/statistics/finance/otherIncome';
import {createFront,frontDetail,updateSingle,singleBackList} from '@/api/order/offlineOrder/list'
export default{
    components:{
        LocalPrint
    },
    props:{
        selectType:String|Number,
        deposit_single_id:String|Number,
    },
   mounted(){
        this.getEmployee()
        //支付方式
        this.getPayChannelCtl();
        console.log(this.selectType)
        if(this.selectType==6){
            this.getDetail()
        }
    },
    updated(){
        this.$nextTick(() => {
            this.$refs.payData.doLayout();
        })
    },
    data(){
        return{
            saveLoading:false,
            addVisible:false,
            pickerBeginDateBefore: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            form:{
                type_seq:'',
                create_time:new Date() ,
                customer_id:null,//客户id
                salesman_store_id:'',//业务员id
                note:'',
                back_id:'',//退定金Id
            },
            options: [{
                value: 10,
                label: '收定金'
            }, {
                value: 20,
                label: '退定金'
            }],
            list:[{pay_channel_id:'',amount:'',detail_note:''}],//表单列表
            userList:[], //客户列表
            employee: [], // 员工列表
            pay_channel: [], // 支付方式
            selectLoading:false,
            addForm:{
                status: 10,
                type: 10,
            },
            rules:{
                type_seq:[{required: true, message: '请选择定金类型', trigger: 'change' }],
                customer_id:[{required: true, message: '请输入客户姓名或电话', trigger: 'change' }],
                salesman_store_id:[{required: true, message: '请选择业务员', trigger: 'change' }],
            },
            returnList:[],
            totalSum:'',
            isFalg:false,
        }
    },
    methods:{

        //查询可退定金单
        getsingleBackList(){
            singleBackList({
                client_user_id:this.form.customer_id
            }).then(res=>{
                if(res.code==1){
                    this.returnList=res.data.list
                }
            })
        },

        //可退定金
        selectChange4(e){
            let arr=this.returnList.filter((item,index)=>{
                return item.deposit_single_id==this.form.back_id
            })
            console.log(arr)
            this.totalSum=arr[0].detail_sum
            this.getDetail()
        },

        //业务员select
        selectChange(e){
            this.form.salesman_store_id=e
            this.$forceUpdate()
        },

        //客户select
        selectChange2(e){
            this.form.customer_id=e
            //是否推定金类型
            if(this.form.type_seq==20){
                this.getsingleBackList()
            }
            this.$forceUpdate()
        },

        //定金类型select
        selectChange3(e){
            console.log(e)
            //客户id不为空&&退定金类型
            if(e==20&&this.form.customer_id){
                this.getsingleBackList()
            }else{
                this.returnList=[]
                this.list=[{pay_channel_id:'',amount:'',detail_note:''}]
                this.form.back_id=''
                this.totalSum=''
            }
        },

         //获取详情
        getDetail(){
            frontDetail({
                deposit_single_id:this.form.back_id?this.form.back_id:this.deposit_single_id,
            }).then(res=>{
                if(res.code==1){
                    if(!this.form.back_id){
                        this.form=res.data
                        this.form.type_seq=res.data.type_seq
                        this.form.salesman_store_id=res.data.business_user_id
                    }
                    this.list=res.data.detail
                    this.form.customer_id=res.data.client_user_id
                    this.getUserList(res.data.client_user_name)

                }
            })
        },


        // 支付方式列表
        getPayChannelCtl() {
            getPayChannelCtlReq({ status: 10, page: 1, limit: 99999 }).then((res) => {
                if (res.code === 1) {
                this.pay_channel = res.data;
                }
            });
        },

        onSubmit(formName){
            let arr=[]
            if(this.selectType===6){
                this.updateSingle()
            }else{
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        console.log(this.list)
                        this.list.forEach((item,index) => {
                            for(let key in item){
                                if(item['amount']&&item['pay_channel_id']){
                                    arr.push(true)
                                }else{
                                    arr.push(false)
                                }
                            }
                        });
                        let falg= arr.every((item,index)=>{
                            if(item){
                                return true;
                            }
                        })
                        if(falg){
                            this.createFront()
                        }else{
                            console.log()
                             this.$message({
                                showClose: true,
                                message: '请填写完整的表格信息',
                                type: 'warning'
                            });
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        },

        //更新定金单
        updateSingle(){
            
            updateSingle({
                deposit_single_id:this.deposit_single_id,
                deposit_single_time:this.form.create_time,
                client_user_id:this.form.customer_id,
                business_user_id:this.form.salesman_store_id,
                type_seq:this.form.type_seq,
                detail_list:this.list,
                note:this.form.note,
                back_id:this.form.back_id,
            }).then(res=>{
                if(res.code===1){
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    });
                    setTimeout(()=>{
                        this.$emit("onAddHide");
                    },1500)
                }
            })
        },

        //创建定金单
        createFront(){
            //防止多次提交
            if(this.isFalg){
                return;
            }
            //判断金额是否相等
            let sum=0;
            this.list.forEach(item=>{
                sum+=parseInt(item.amount)
            })
            if(parseInt(this.totalSum)!=sum&&this.form.back_id){
                this.$message({
                    message: '金额不相等',
                    type: 'warning'
                });
                return;
            }
            createFront({
                deposit_single_time:this.form.create_time,
                client_user_id:this.form.customer_id,
                business_user_id:this.form.salesman_store_id,
                type_seq:this.form.type_seq,
                detail_list:this.list,
                note:this.form.note,
                back_id:this.form.back_id,
            }).then(res=>{
                if(res.code===1){
                    this.isFalg=true
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    });
                    setTimeout(()=>{
                        this.isFalg=false
                        this.$emit("onAddHide");
                    },1500)
                }
            })
        },

         handleClose() {
            // this.dialogVisible = false;
            this.addVisible = false;
        },

         // 新增款项名称
        handleAddStreamType() {
            addStreamTypeReq({
                ...this.addForm,
            })
            .then((res) => {
            if (res.code === 1) {
                this.$message.success('新增成功');
                this.getstreamTypeList();
                this.addVisible = false;
            }
            });
        },

         // 获取员工列表
        getEmployee() {
            getEmployeeReq()
            .then((res) => {
                if (res.code === 1) {
                    this.employee = res.data;
                }
            });
        },

        //客户列表
        remoteMethod(key) {
            if (!key) {
                return;
            }
            this.getUserList(key);
        },

         // 获取客户列表
        getUserList(search) {
            this.selectLoading = true;
            getUserListReq({
                page: 1,
                limit: 99,
                search,
            })
            .then((res) => {
            this.selectLoading = false;
            if (res.code === 1) {
                this.userList = res.data.list;
            }
            })
            .catch(() => {
            this.selectLoading = false;
            });
        },

        deleteRow(index){
            console.log(index)
            this.list.splice(index,1)
        },

        addRow(){
            this.list.push({pay_channel_id:'',amount:'',detail_note:''})
        },

        goBack() {
            this.$emit("onAddHide");
            // this.mountOrder = false;
        },
    }
}
</script>

<style lang='less' scoped>
.form {
  background: white;
  padding: 10px 0;
  /deep/ .el-form-item {
    margin: 10px 0;
   }
}
// /deep/ .el-textarea__inner{
//     height:200px !important;
// }
.btn2 {
  background: white;
  display: flex;
  justify-content: center;
  border-top: 1px solid #ddd;
  padding: 10px 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
.submitForm{
//   position: fixed;  
//   left: 80px;
//   right: 0;
//   bottom: 90px;
  background: #fff;
}

.inputText{
    height:38px !important;
}
</style>