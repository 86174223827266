<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" :content="selectType===1?'定金单详情':'定金单审核'"> </el-page-header>
    </div>

    <div class="form" >
        <el-descriptions >
            <el-descriptions-item label="定金单号">{{detailObj.sn}}</el-descriptions-item>
            <el-descriptions-item label="单据日期">{{detailObj.deposit_single_time}}</el-descriptions-item>
            <el-descriptions-item label="定金类型">
                <el-tag size="small" v-if="detailObj.type_seq===10" >收定金</el-tag>
                <el-tag size="small" v-if="detailObj.type_seq===20">退定金</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="门店">{{detailObj.hierarchy_name}}</el-descriptions-item>
            <el-descriptions-item label="业务员">{{detailObj.business_user_name}}</el-descriptions-item>
            <el-descriptions-item label="客户昵称">{{detailObj.client_user_name}}</el-descriptions-item>
            <el-descriptions-item label="单据状态">
                <el-tag size="small" v-if="detailObj.status===10" type="info">待审核</el-tag>
                <el-tag size="small" v-if="detailObj.status===20">已通过</el-tag>
                <el-tag size="small" v-if="detailObj.status===30" type="warning">未通过</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="创建人">{{detailObj.store_user_name}}</el-descriptions-item>
             <el-descriptions-item label="创建时间">{{detailObj.create_time}}</el-descriptions-item>
             <!-- <el-descriptions-item label="备注">{{detailObj.note}}</el-descriptions-item> -->
        </el-descriptions>

        <template>
           <el-button
              type='primary'
              plain
              style="margin:0 10px; height: 40px;"
              @click='detailExport'
              icon='el-icon-upload2'>
              导出
          </el-button>
        </template>

         <!-- 表格 -->
            <el-table  :data="list" height="300px" border sum-text='总价' ref="payData"  style="width: 100%;margin-top:20px;" :summary-method="getSummaries" show-summary>
                <el-table-column
                    type="index"
                    width="150"
                    align="center"
                    label="序号">
                </el-table-column>

                <el-table-column
                    align="center"
                    prop="amount"
                    label="金额"
                    width="400px">
                </el-table-column>

                <el-table-column
                    align="center"
                    label="支付方式"
                    prop="pay_channel_name"
                    width="400px">
                  <template slot-scope="scope">
                      {{scope.row.name}}
                        <el-select disabled 
                            v-model="list[scope.$index].pay_channel_id">
                        <template v-for="item in pay_channel" >
                        <el-option aria-readonly=""
                            :label="item.name"
                            :value="item.pay_type"
                            :key="item.pay_type"></el-option>
                        </template>
                    </el-select>
                   </template>
                </el-table-column>

                  <el-table-column
                    prop="detail_note"
                    align="center"
                    label="明细备注">
                      
                </el-table-column>

            </el-table>

            <div style="margin-top:40px;">
              <el-form>
                  <el-form-item label="备注">
                    <el-input style="width:30%;" :readonly="selectType==1?true:false" type="textarea" v-model="detailObj.note" />
                  </el-form-item>
              </el-form>
            </div>

            <template v-if="selectType==1">
                  <el-descriptions :colum=4 style="width:66%;">
                      <el-descriptions-item label="审核人">{{detailObj.audit_store_user_name?detailObj.audit_store_user_name:'-'}}</el-descriptions-item>
                      <el-descriptions-item label="审核时间">{{detailObj.audit_time?detailObj.audit_time:'-'}}</el-descriptions-item>
                  </el-descriptions>
              </template>

        <div class="buttons" v-if="detailObj.status===10&&selectType!==1">
            <el-button type="primary" @click="open(20)">审核通过</el-button>
            <el-button type="primary" plain @click="open(30)">审核不通过</el-button>
        </div>

    </div>

    
    <el-dialog
      title="审核不通过原因"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <el-form >
        <el-form-item label="原因说明" required="">
          <el-input type="textarea" :rows="3" v-model="audit_info" placeholder="请输入审核不通过原因说明"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="depositSingle(30)">提 交</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {frontDetail,depositSingle,detailExport} from '@/api/order/offlineOrder/list'
import {getPayChannelCtlReq}  from '@/api/statistics/finance/otherIncome';
export default {
  props:{
    deposit_single_id:String|Number,
    selectType:String|Number,
  },
  mounted(){
    this.getDetail()
    this.getPayChannelCtl()
  },
  updated(){
    this.$nextTick(() => {
      this.$refs.payData.doLayout();
    })
  },
  data() {
    return {
      detailObj:{},
      pay_channel:[],
      list:'',
      dialogVisible:false,
      audit_info:'',//不通过原因
    };
  },
  methods: {

    //导出
    detailExport(){
        detailExport({ deposit_single_id:this.deposit_single_id  })
          .then((res) => {
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const time = new Date().getTime();
          const a = document.createElement("a");
          a.setAttribute("download", `定金单详情列表.xls`);
          a.href = URL.createObjectURL(blob);
          a.click();
          })
          .catch(() => {
          });
    },

     // 合计
    getSummaries({ columns, data }) {
      const sums = [];
      columns.forEach((column,index)=>{
        if(index==0){
          sums[index]='合计'
          return
        }
         const values = Array.from(data).map(item => {
            return Number(item[column.property])//property为表头的prop值
        });
        if (column.property === 'amount') {
          sums[index] = values.reduce((prev, curr) => {
               const value = Number(curr);//Number转换为数值
                let sum =Number(Number(prev) + Number(curr)).toFixed(2);//toFixed(2)数据项保留两位小数
                if (!isNaN(value)) {
                    return sum;
                } else {
                    return prev;
                }
          }, 0);
            sums[index];
          }
      })
      return sums
    },

     // 支付方式列表
      getPayChannelCtl() {
          getPayChannelCtlReq({ status: 10, page: 1, limit: 99999 }).then((res) => {
              if (res.code === 1) {
              this.pay_channel = res.data;
              }
          });
      },

     open(status) {
        if(status==30){
            this.dialogVisible=true
        }else{
          let str=status==20?"通过审核":"不通过审核"
          this.$confirm(`确定${str}?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
              this.depositSingle(status)
          }).catch(() => {});
        }
      },

    //审核定金
    depositSingle(status){
        if(!this.audit_info&&status!==20){
          this.$message({
                type: 'warning',
                message: '请填写不通过理由'
            });
            return;
        }
        depositSingle({
            deposit_single_id:this.detailObj.deposit_single_id,
            status,
            audit_info:this.audit_info,
        }).then(res=>{
            if(res.code==1){
             this.$message({
                type: 'success',
                message: '操作成功!'
             });
             setTimeout(()=>{
                this.goBack()
             },2000)
            }
        })
    },

    //获取详情
    getDetail(){
        frontDetail({
            deposit_single_id:this.deposit_single_id
        }).then(res=>{
            if(res.code==1){
                this.detailObj=res.data
                this.list=res.data.detail
            }
        })
    },

    goBack() {
      this.$emit("onBack");
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
  
};
</script>

<style lang="less" scoped>
.buttons {
  display: flex;
  background: white;
  padding: 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
}
.form{
    background: #fff;
    padding:20px;
}
/deep/ .el-input__suffix{
  display: none !important;
}
/deep/ .el-input__inner{
  border: 1px solid #fff !important;
  background: #fff !important;
  color: #606266 !important;
  text-align: center;
}
/deep/ .el-select{
  width:100%;
}
</style>
