<template>
    <div  class="content" style="background: #f6f7fb">
        
         <template v-if="!dialogVisible&&!examineVisible">
            <div class="card" style="display: flex">
                <div class="left" style="flex-shrink: 0; display: flex">
                    <el-button
                        style="height: 40px;"
                        type="primary"
                        @click="addFront"
                        icon="el-icon-plus">新增定金单</el-button>
                </div>

                  <el-button
                    type='primary'
                    plain
                    style="margin:0 10px; height: 40px;"
                    @click='handleExport'
                    icon='el-icon-upload2'
                    >
                    <!-- :loading="loading3" -->
                    导出
                </el-button>

                <div class="line"></div>
                <div class="middle" style="flex: 1">
                    <Cond
                        @onCond="handleCond"
                        function_code="depositSingle"
                        @setCond="handleSetCond"
                    />
                 </div>
            </div>

            <!-- 表格 -->
              <div
                class="table"  
                v-loading="loading"
                style="border: 1px solid #ddd; border-radius: 6px; overflow:hidden;margin-top:10px">
                    
                    <!-- :summary-method="getSummaries" show-summary   -->
                    <el-table height="700" ref='refundBillOperationImg'
                        :data="tableData" border show-summary :summary-method="getSummaries"
                        style="width: 100%">

                             <el-table-column
                                type="index"
                                width="50"
                                align="center"
                                label="序号">
                            </el-table-column>

                            <el-table-column
                                prop="sn"
                                label="定金单号"
                                align="center"
                                width="180">
                            </el-table-column>

                            <el-table-column
                                prop="deposit_single_time"
                                label="单据日期"
                                align="center"
                                width="180">
                            </el-table-column>

                            <el-table-column
                                prop="type_seq"
                                align="center"
                                label="单据类型">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.type_seq==10" type="primary">收定金</span>
                                    <span v-if="scope.row.type_seq==20" type="primary">退定金</span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                prop="status"
                                align="center"
                                label="单据状态">
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.status==10" type="warning">待审核</el-tag>
                                    <el-tag v-if="scope.row.status==20" type="success">审核通过</el-tag>
                                    <el-tag v-if="scope.row.status==30" type="danger">审核不通过</el-tag>
                                </template>
                            </el-table-column>

                            <el-table-column
                                prop="hierarchy_name"
                                align="center"
                                label="门店">
                            </el-table-column>

                            <el-table-column
                                prop="client_user_name"
                                align="center"
                                label="客户昵称">
                            </el-table-column>

                            <el-table-column
                                prop="detail_sum"
                                align="center"
                                label="定金金额">
                            </el-table-column>

                             <el-table-column
                                prop="business_user_name"
                                align="center"
                                label="业务员">
                            </el-table-column>

                            <el-table-column
                                prop="store_user_name"
                                align="center"
                                label="创建人">
                            </el-table-column>

                            <el-table-column
                                prop="create_time"
                                align="center"
                                label="创建时间">
                            </el-table-column>

                            <el-table-column
                                prop="update_store_user_name"
                                align="center"
                                label="最后修改人">
                            </el-table-column>

                              <el-table-column
                                prop="update_time"
                                align="center"
                                label="最后修改时间">
                            </el-table-column>

                            <el-table-column
                                prop="audit_store_user_name"
                                align="center"
                                label="审核人">
                            </el-table-column>

                            <el-table-column
                                prop="audit_time"
                                align="center"
                                label="审核时间">
                            </el-table-column>

                             <el-table-column
                                prop=""
                                align="center"
                                label="单据来源">
                            </el-table-column>

                            <el-table-column
                                align="center"
                                label="操作"
                                width="130px" fixed="right">
                                <template slot-scope="scope">
                                    
                                <div class="options">
                                    <el-select style="width:100%;"
                                        v-model="selectType"
                                        @change="handleChange($event,scope.row,scope.$index)">
                                        <!-- 待审核 -->
                                        <template v-if="scope.row.status == 10"> 
                                            <el-option label="详情" :value="1"></el-option>
                                            <el-option label="审核" :value="2"></el-option>
                                            <el-option label="打印" :value="3"></el-option>
                                        </template>
                                        <!-- 审核通过 -->
                                        <template v-else-if="scope.row.status == 20">
                                            <el-option label="详情" :value="1"></el-option>
                                            <el-option label="打印" :value="3"></el-option>
                                            <el-option label="解锁" v-if="!noSockIds.includes(scope.row.deposit_single_id)" :value="4"></el-option>
                                            <el-option label="编辑" v-if="noSockIds.includes(scope.row.deposit_single_id)" :value="6"></el-option>
                                            <el-option label="删除" v-if="noSockIds.includes(scope.row.deposit_single_id)" :value="7"></el-option>
                                        </template>
                                        <!-- 审核不通过 -->
                                        <template v-else-if="scope.row.status == 30">
                                            <el-option label="详情" :value="1"></el-option>
                                            <el-option label="打印" :value="3"></el-option>
                                            <el-option label="失败原因" :value="5"></el-option>
                                            <el-option label="编辑" :value="6"></el-option>
                                            <el-option label="删除" :value="7"></el-option>
                                        </template>
                                    </el-select>
                                </div>

                                </template>
                            </el-table-column>

                        </el-table>

                        </div>

                           <div class="pagina">
                                <el-pagination
                                :current-page="page"
                                background
                                @size-change="handleSizeChange"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="total"
                                :page-size="$store.state.pageSizes[0]"
                                :page-sizes="$store.state.pageSizes"
                                @current-change="handleCurrentChange"></el-pagination>

              </div>
         </template>

          <el-dialog
            title="失败原因"
            :visible.sync="failDialogVisible"
            width="30%"
            :before-close="handleClose">
            <span>{{audit_info}}</span>
            </el-dialog>

        <Add  v-if="dialogVisible" :selectType="selectType2" :deposit_single_id="deposit_single_id" @onAddHide="handleAddHide"  />

        <Examine v-if="examineVisible" :selectType="selectType2" :deposit_single_id="deposit_single_id" @onBack="handleBack" />

         <!-- 打印 -->
          <LocalPrint
            :show="false"
            :printRows="printRows"
            :printData="printData"
            :title="printTitle"
            :dialogVisible="localPrintVisible"
            @onClose="localPrintVisible = false"
            />
    </div>
</template>

<script>
import Cond from "@/components/cond/Index.vue";
import PrintConf from "@/components/printConf/Index.vue";
import Examine from './examine.vue'
import Add from "./add.vue";
import LocalPrint from "@/components/localPrint/Index.vue";
import {frontList,exportForont,frontDetail,deleteSingle} from '@/api/order/offlineOrder/list'
import { getIsLockReq } from '@/api/statistics/finance/otherIncome';
export default{
    components:{Cond,PrintConf,Add,Examine,LocalPrint},
    created(){
        this.getList()
    },
    data(){
        return{
            currRow: {},
            noSockIds: [],
            dialogVisible:false,
            examineVisible:false,//审核页面
            cond: {}, // 搜索的配置
            page:1,
            total:0,
            limit:50,
            loading: false,   
            tableData: [],
            formType: '', // detail详情  add新增  edit编辑
            selectType:'',//选择类型 审核 打印
            deposit_single_id:'',//详情id
            selectType2:'',
            failDialogVisible:false,//失败原因开关
            audit_info:'', //失败理由
            localPrintVisible:false,//打印开关
            printData: {},  //打印信息
            printRows: [],  //打印行
            printTitle:'',  //打印标题
            columnList2: [
                { field_text: "序号", field_alias: "index", width: 10 },
                { field_text: "支付方式", field_alias: "pay_channel_name", width: 66 },
                { field_text: "金额", field_alias: "amount", width: 66 },
                { field_text: "备注", field_alias: "detail_note", width: 66 },
            ],
        }
    },

    updated() {
        this.$nextTick(() => {
            this.$refs["refundBillOperationImg"].doLayout();
        });
    },

    methods:{

        //删除
        deleteSingle(){
            deleteSingle({
                deposit_single_id:this.deposit_single_id
            }).then((res=>{
                if(res.code==1){
                     this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getList();
                }
            }))
        },

        //关闭打印
        handleClose(){
            this.failDialogVisible=false
        },

        //新增定金单
        addFront(){
            this.dialogVisible = true
            this.selectType2=''
        },

        //操作
        handleChange(e,row,index){
            this.selectType2=this.selectType
            this.deposit_single_id=row.deposit_single_id
            if(this.selectType2===1||this.selectType2===2){ //1详情 2审核
                this.examineVisible=true
            }else if(this.selectType2==5){
                this.audit_info=row.audit_info
                this.failDialogVisible=true
            }else if(this.selectType2==3){
                frontDetail({
                    deposit_single_id:row.deposit_single_id
                }).then((res=>{
                    row.select = '';
                    if(res.code===1){
                        const data = res.data;
                        this.printTitle = '定金单';  
                        let top=[];
                        const arr1=[
                            { txt: "定金单号", value: data.sn, width: "22%" },
                            { txt: "单据日期", value: data.deposit_single_time, width: "25%" },
                            { txt: "定金类型", value: data.type_seq==10?'收定金':'退定金', width: "20%" },
                            { txt: "客户", value: data.client_user_name?data.client_user_name:'-', width: "20%" },
                        ]
                        top.push(arr1);
                        const arr2 = [
                            { txt: "门店", value: data.hierarchy_name, width: "20%" },
                            { txt: "创建人", value: data.store_user_name, width: "20%" },
                            { txt: "创建时间", value: data.create_time, width: "30%" },
                        ];
                        top.push(arr2);
                        let bottom = [];
                        const arr4 = [
                            { txt: "审核时间", value: data.audit_time, width: "24%" },
                            { txt: "审核人", value: data.audit_store_user_name, width: "24%" },
                        ];
                        bottom.push(arr4)
                        this.printRows = this.columnList2;
                        data.detail.forEach((item, index) => {
                            item.index = index + 1;
                            console.log(item)
                        })
                        this.printData = {
                            top,
                            list: data.detail,
                            bottom,
                        }
                        this.localPrintVisible=true
                    }
                
                })) 
            }else if(this.selectType2==4){ //解锁
                    console.log(index)
                    getIsLockReq({
                        source: 'deposit_single',
                        primary_key_id: this.deposit_single_id,
                    })
                    .then((res) => {
                          this.currRow = {};
                          this.noSockIds.push(row.deposit_single_id);
                          row.select = '';
                          this.$message.success('解锁成功');
                    })
                    .catch(() => {
                    });   
            }
            else if(this.selectType2===6){ //6编辑
                this.dialogVisible=true
            }else if(this.selectType2===7){ //删除
                 this.$confirm(`确定删除?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.deleteSingle()
                }).catch(() => {});
            }
            this.selectType=''
        },

        handleBack() {
            this.dialogVisible = false;
            this.examineVisible=false;
            this.getList();
        },

       // 合计
        getSummaries({ columns, data }) {
        const sums = [];
        columns.forEach((column,index)=>{
            if(index==0){
            sums[index]='合计'
            return
            }
            const values = Array.from(data).map(item => {
                return Number(item[column.property])//property为表头的prop值
            });
            if (column.property == 'detail_sum' ) {

            sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);//Number转换为数值
                    let sum =Number(Number(prev) + Number(curr)).toFixed(2);//toFixed(2)数据项保留两位小数
                    if (!isNaN(value)) {
                        return sum;
                    } else {
                        return prev;
                    }
            }, 0);
                sums[index];
            }
        })
        return sums
        },

        // 导出
        handleExport() {
            exportForont({ ...this.cond, page: this.page, limit: this.limit,  })
                .then((res) => {
                const blob = new Blob([res], { type: "application/vnd.ms-excel" });
                const time = new Date().getTime();
                const a = document.createElement("a");
                a.setAttribute("download", `定金单列表.xls`);
                a.href = URL.createObjectURL(blob);
                a.click();
            })
            .catch(() => {
            });
         },

        //获取定金单列表
        getList(){
            frontList({ ...this.cond, page: this.page, limit: this.limit })
            .then(res=>{
                if(res.code===1){
                    console.log(res)
                    this.tableData=res.data.list
                    this.tableData.forEach(item=>{
                        item.isLock=true
                    })
                    console.log(this.tableData)
                    this.total=res.data.total
                }
            })
        },

         // 新增模块隐藏事件
        handleAddHide(getList) {
            this.dialogVisible = false;
            // this.reverse = false;
            // // 返回时将提单号清空
            // this.order_number = "";
            // this.order_id = '';
            // if (getList) {
            //     this.page = 1;
                this.getList();
            // }
        },

         // 改变分页条数
        handleSizeChange(size) {
            this.limit = size;
            this.getList();
        },

        handleCurrentChange(page) {
            this.page = page;
            this.getList();
        },

        //搜索配置
        handleSetCond(cond) {
            this.page = 1;
            this.cond = { ...cond };
        },

        handleCond(cond) {
            this.page = 1;
            this.cond = { ...cond };
            this.getList();
        },

    }
}
</script>

<style lang='less' scope>
/deep/ .el-table {
    overflow: visible !important;
}
</style>